<template>
  <div>
    <div class="line-merchorder-page pt-3">
      <div class="tw-container" style="max-width: 1200px; margin: auto">
        <p class="text-center tw-text-size18 fw-bolder mb-2">我的訂單</p>
        <hr />
        <NRMerchOrder></NRMerchOrder>
      </div>
    </div>
  </div>
</template>

<script>
// components
import NRMerchOrder from '@/components/noRegistration/NR_MerchOrder.vue'

export default {
  components: { NRMerchOrder },
}
</script>